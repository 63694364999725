export const errors = {
  1: "Помилка запиту",
  2: "id не знайдено",
  3: "Запит не знайшов результатів",
  4: "Перевищено ліміт запитів .Дозволена кількість запитів 1 в 5 секунд .",
  5: "Не актуальна підбірка",
  6: "type_table не знайдено",
  7: "type_table невалідний",
  8: "Помилка запиту",
  9: "Обєкт уже існує в таблиці лайків",
  10: "Не передано будь-які обов`язкові параметри",
  11: "Помилка при завантажені зображення",
  12: "id_user_hash не знайдено або пусте",
  13: "Чат не знайдено",
  14: "Підбірка не існує",
  15: "Не передано like",
  16: "Помилка при завантаженні зображення",
  17: "Не передано folder або не валідний формат",
  18: "Не передано id",
  19: "Інформацію не знайдено",
};
